<template>
  <div class="content">
    <TitleInfo title1="设备管理" title2="设备类型"></TitleInfo>
    <div class="main">
      <div class="title flexCB">
        <div class="key align-center">
          <div>类型名称：</div>
          <el-input
            v-model="queryForm.name"
            placeholder="请输入类型名称"
            class="keyInput"
          />
          <div>类型编码：</div>
          <el-input
            v-model="queryForm.code"
            placeholder="请输入类型编码"
            class="keyInput"
          />
          <el-button
            :icon="Search"
            color="#11488B"
            :round="false"
            @click="initGetList"
            >搜索</el-button
          >
          <el-button
            :icon="Refresh"
            color="#67C23A"
            :round="false"
            @click="resetInfo"
            >重置</el-button
          >
          <el-button
            v-if="user.CanAdd"
            :icon="Plus"
            type="warning"
            :round="false"
            @click="handleDialogValue()"
            >新增</el-button
          >
          <el-button
            v-if="user.CanDownload"
            :icon="Download"
            type="danger"
            :round="false"
            @click="handleDownLoadModel()"
            >参数模板下载</el-button
          >
        </div>
      </div>
      <div class="tableInfo">
        <el-table
          :data="tableData"
          border
          class="tableInfo"
          style="width: 100%; height: 100%"
        >
          <el-table-column type="index" width="50"> </el-table-column>
          <el-table-column prop="Name" label="类型名称"></el-table-column>
          <el-table-column prop="Code" label="类型编码"></el-table-column>
          <el-table-column prop="Remark" label="备注信息"></el-table-column>
          <el-table-column prop="action" label="操作" width="500">
            <template #default="{ row }">
              <el-button
                v-if="user.CanEdit"
                size="small"
                type="warning"
                :icon="Edit"
                @click="handleDialogValue(row)"
                >编辑</el-button
              >
              <el-button
                v-if="user.CanDelete"
                size="small"
                type="danger"
                :icon="Delete"
                @click="del(row)"
                >删除</el-button
              >
              <el-button
                size="small"
                type="warning"
                :icon="View"
                @click="parameDetails(row)"
                >参数查看</el-button
              >
              <el-button
                v-if="user.CanDownload"
                size="small"
                type="success"
                :icon="Download"
                @click="downLoad(row)"
                >参数下载</el-button
              >
              <el-button
                v-if="user.CanUpload"
                size="small"
                type="primary"
                :icon="Upload"
                @click="upLoad(row)"
                >参数上传</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="bottom align-center">
        <el-pagination
          v-model:current-page="queryForm.pageIndex"
          v-model:page-size="queryForm.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :background="true"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          popper-class="dropdownPopper"
        />
      </div>
    </div>
    <Dialog
      v-model="dialogVisible"
      :dialogTitle="dialogTitle"
      :dialogRowValue="rowInfo"
      v-if="dialogVisible"
      @initList="initGetList"
    ></Dialog>
    <UpLoadDialog
      v-model="upLoadDialogVisible"
      :dialogTitle="dialogTitle"
      :dialogRowID="rowID"
      v-if="upLoadDialogVisible"
    ></UpLoadDialog>
    <ParamesDialog
      v-model="paramesDialogVisible"
      :dialogTitle="dialogTitle"
      :dialogRowID="rowID"
      v-if="paramesDialogVisible"
    ></ParamesDialog>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue'
import {
  Search,
  Refresh,
  Plus,
  Edit,
  Delete,
  Setting,
  Download,
  Upload,
  View
} from '@element-plus/icons-vue'
import { isNull } from '@/utitls/validate'
import { ElMessage, ElMessageBox } from 'element-plus'
import TitleInfo from '@/components/TitleInfo'
import { GetDeviceTypePageList, DeleteDeviceType } from '@/api/DeviceType'
import { GetParameModel, DownloadDeviceParames } from '@/api/DeviceParame'
import Dialog from './components/dialog.vue'
import UpLoadDialog from './components/upLoadDialog.vue'
import ParamesDialog from './components/paramesDialog.vue'
import { useStore } from 'vuex'
const queryForm = ref({
  name: '',
  code: '',
  pageIndex: 1,
  pageSize: 10
})
const total = ref(0)
const tableData = reactive([])
const dialogVisible = ref(false)
const dialogTitle = ref('')
const rowInfo = ref({})
const upLoadDialogVisible = ref(false)
const paramesDialogVisible = ref(false)
const rowID = ref('')
const user = ref({})
onMounted(() => {
  user.value = useStore().state.login.user
  initGetList()
})
const initGetList = async () => {
  GetDeviceTypePageList(queryForm.value)
    .then((res) => {
      if (res) {
        total.value = res.Total
        tableData.length = 0
        res.Data.forEach((element) => {
          tableData.push(element)
        })
      } else {
        total.value = 0
        tableData.length = 0
      }
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `${err}`,
        type: 'error'
      })
      console.log(err)
      total.value = 0
      tableData.length = 0
    })
}
const handleSizeChange = (pageSize) => {
  queryForm.value.pageIndex = 1
  initGetList()
}
const handleCurrentChange = (pageNum) => {
  initGetList()
}
const handleDialogValue = (row) => {
  if (isNull(row)) {
    dialogTitle.value = '添加设备类型'
    rowInfo.value = {}
  } else {
    dialogTitle.value = '修改设备类型信息'
    rowInfo.value = JSON.parse(JSON.stringify(row))
  }
  dialogVisible.value = true
}
const del = (row) => {
  ElMessageBox.confirm('是否确认删除当前设备类型?', '提醒', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async () => {
      DeleteDeviceType({ id: row.ID })
        .then((res) => {
          if (res) {
            ElMessage({
              showClose: true,
              type: 'success',
              message: '删除成功!'
            })
            initGetList()
          }
        })
        .catch((err) => {
          ElMessage({
            showClose: true,
            type: 'error',
            message: err,
            duration: 2000
          })
        })
    })
    .catch(() => {})
}
const downLoad = (row) => {
  DownloadDeviceParames({ deviceTypeID: row.ID, deviceTypeName: row.Name })
    .then((response) => {
      let blob = new Blob([response.data], { type: response.data.type })
      // 针对于 IE 浏览器的处理, 因部分 IE 浏览器不支持 createObjectURL
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, response.fileName)
      } else {
        var downloadElement = document.createElement('a')
        var href = window.URL.createObjectURL(blob) // 创建下载的链接
        downloadElement.href = href
        downloadElement.download = response.fileName // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href) // 释放掉 blob 对象
      }
    })
    .catch((err) => {
      console.log(err)
    })
}
const upLoad = (row) => {
  dialogTitle.value = row.Name
  rowID.value = row.ID
  upLoadDialogVisible.value = true
}
const resetInfo = () => {
  queryForm.value = {
    name: '',
    code: '',
    pageIndex: 1,
    pageSize: 10
  }
  initGetList()
}
const handleDownLoadModel = () => {
  GetParameModel({})
    .then((response) => {
      let blob = new Blob([response.data], { type: response.data.type })
      // 针对于 IE 浏览器的处理, 因部分 IE 浏览器不支持 createObjectURL
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, response.fileName)
      } else {
        var downloadElement = document.createElement('a')
        var href = window.URL.createObjectURL(blob) // 创建下载的链接
        downloadElement.href = href
        downloadElement.download = response.fileName // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href) // 释放掉 blob 对象
      }
    })
    .catch((err) => {
      console.log(err)
    })
}
const parameDetails = (row) => {
  dialogTitle.value = row.Name
  rowID.value = row.ID
  paramesDialogVisible.value = true
}
</script>
<style lang="scss" scoped src="@/styles/elementReset.scss"></style>
<style lang="scss" scoped src="./assets/index.scss"></style>
