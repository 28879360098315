<template>
  <el-dialog :model-value="dialogVisible" :title="title" width="900px" @opened="handleOpen" @close="handleClose"
    draggable>
    <el-table :data="tableData" border class="tableInfo" style="width: 100%; height: 100%" height="600">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="Name" label="参数名称"></el-table-column>
      <el-table-column prop="DCSName" label="位号名称"></el-table-column>
      <el-table-column prop="Unit" label="参数单位"></el-table-column>
      <el-table-column prop="Sort" label="序号"></el-table-column>
      <el-table-column prop="Type" label="数据类型"></el-table-column>
      <el-table-column prop="ToHistoryLabel" label="存为历史"></el-table-column>
      <el-table-column prop="RatioX" label="乘系数"></el-table-column>
      <el-table-column prop="RatioAdd" label="加系数"></el-table-column>
      <el-table-column prop="Round" label="保留位数"></el-table-column>
      <el-table-column prop="ReadType" label="读写权限"></el-table-column>
      <el-table-column prop="BeAlarmLabel" label="报警判定"></el-table-column>
      <el-table-column prop="BeFaultLabel" label="故障判定"></el-table-column>
      <el-table-column prop="BePowerLabel" label="耗电参数"></el-table-column>
      <el-table-column prop="BeEnergyLabel" label="能耗参数"></el-table-column>
      <el-table-column prop="Remarks" label="备注"></el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { GetDeviceParameList } from '@/api/DeviceParame'
import { ElMessage } from 'element-plus'
import { isNull } from '@/utitls/validate'
const props = defineProps({
  dialogTitle: {
    type: String,
    default: '',
    required: true
  },
  dialogRowID: {
    type: String,
    default: '',
    default: () => { }
  }
})
const dialogVisible = ref(false)
const title = ref('设备参数详情')
const tableData = reactive([])
const emits = defineEmits(['update:modelValue'])
const handleOpen = () => {
  title.value = props.dialogTitle + '设备参数详情'
  GetDeviceParameList({ deviceTypeID: props.dialogRowID })
    .then((res) => {
      tableData.length = 0
      res.Data.forEach((element) => {
        tableData.push(element)
      })
    })
    .catch((err) => {
      console.log(err)
    })
}
const handleClose = () => {
  emits('update:modelValue', false)
}
const handleConfirm = () => {
  handleClose()
}
</script>

<style scoped></style>
