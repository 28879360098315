<template>
  <el-dialog :model-value="dialogVisible" :title="title" width="700px" @opened="handleOpen" @close="handleClose"
    draggable>
    <el-upload ref="upload" class="upload-demo" action="#" :on-change="handleChangeFile" :auto-upload="false" :limit="1"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" :on-exceed="handleExceed">
      <el-button type="primary">选择文件</el-button>
    </el-upload>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm('add')">追加</el-button>
        <el-button type="primary" @click="handleConfirm('cover')">覆盖</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { UploadDeviceParames } from '@/api/DeviceParame'
import { ElMessage } from 'element-plus'
import { isNull } from '@/utitls/validate'
const props = defineProps({
  dialogTitle: {
    type: String,
    default: '',
    required: true
  },
  dialogRowID: {
    type: String,
    default: '',
    default: () => { }
  }
})
const dialogVisible = ref(false)
const title = ref('设备参数上传')
var uploadDataFile = ref()
const emits = defineEmits(['update:modelValue'])
const handleOpen = () => {
  title.value = props.dialogTitle + '设备参数上传'
}
const handleClose = () => {
  emits('update:modelValue', false)
}
const handleChangeFile = (uploadFile, uploadFiles) => {
  uploadDataFile.value = uploadFile.raw
}
const handleExceed = (files) => {
  upload.value?.clearFiles()
  const file = files[0]
  upload.value?.handleStart(file)
}
const handleConfirm = (type) => {
  if (!isNull(uploadDataFile.value)) {
    let formData = new FormData()
    formData.append('file', uploadDataFile.value)
    formData.append('deviceTypeID', props.dialogRowID)
    formData.append('deviceTypeName', props.dialogTitle)
    formData.append('type', type)
    UploadDeviceParames(formData)
      .then((res) => {
        ElMessage({
          message: '参数上传成功',
          type: 'success'
        })
        handleClose()
      })
      .catch((err) => {
        ElMessage({
          message: `上传失败:${err}`,
          type: 'error'
        })
      })
  } else {
    ElMessage({
      message: `上传失败:文件地址不能为空`,
      type: 'error'
    })
  }
}
</script>

<style scoped></style>
